<template>
  <div class="transaction">
    <div class="row">
      <div class="flex md12">
        <div class="mb-2" style="font-size: 24px;">Transaction History</div>
        <div>This is the list of the transaction that you have made</div>
      </div>
    </div>
    <div class="row">
      <div class="flex md12">
        <va-card>
          <va-card-content>
            <div class="row">
              <div class="flex xs12 md2">
                <va-input v-model="simple" placeholder="Text Input" />
              </div>
              <div class="flex xs12 md3 ">
                <va-input v-model="simple" placeholder="Statistic Title" />
              </div>
              <div class="flex xs12 md3 ">
                <va-input v-model="simple" placeholder="Price" />
              </div>
              <div class=" flex xs12 md3 ">
                <va-input v-model="simple" placeholder="Category" />
              </div>
              <div class=" flex xs12 md1">
                <va-button color="primary">
                  Search
                </va-button>
              </div>
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
    <div class="row transaction-tables">
      <div class="flex md12 xl12">
        <va-card :title="$t('tables.stripedHoverable')">
          <va-card-content>
            <div class="table-wrapper">
              <table class="va-table va-table--striped va-table--hoverable">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Transaction Date</th>
                    <th>Category</th>
                    <th>Statistic</th>
                    <th>Price</th>
                    <th>Buyer</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    class="p-4"
                    v-for="transaction in transactions"
                    :key="transaction.id"
                  >
                    <td>{{ transaction.no }}</td>
                    <td>{{ transaction.date }}</td>
                    <td>{{ transaction.category }}</td>
                    <td>{{ transaction.statistic }}</td>
                    <td>{{ transaction.price }}</td>
                    <td>{{ transaction.buyer }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
    <div class="row">
      <div class="flex xs12 xl12">
        <va-pagination
          class="mx-auto"
          v-model="activePage"
          :visible-pages="3"
          :pages="20"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transactions: [
        {
          no: 1,
          date: "20-12-2021",
          category: "ADVERTISING & MARKETING",
          statistic:
            "In 2021, global digital video advertising spending was projected to amount to 91.5 billion U.S. dollars, roughly 20 percent ...",
          price: "IDR 700.000",
          buyer: "Muhamad Wendy"
        },
        {
          no: 2,
          date: "20-12-2021",
          category: "ADVERTISING & MARKETING",
          statistic:
            "In 2021, global digital video advertising spending was projected to amount to 91.5 billion U.S. dollars, roughly 20 percent ...",
          price: "IDR 700.000",
          buyer: "Muhamad Wendy"
        },
        {
          no: 3,
          date: "20-12-2021",
          category: "ADVERTISING & MARKETING",
          statistic:
            "In 2021, global digital video advertising spending was projected to amount to 91.5 billion U.S. dollars, roughly 20 percent ...",
          price: "IDR 700.000",
          buyer: "Muhamad Wendy"
        },
        {
          no: 4,
          date: "20-12-2021",
          category: "ADVERTISING & MARKETING",
          statistic:
            "In 2021, global digital video advertising spending was projected to amount to 91.5 billion U.S. dollars, roughly 20 percent ...",
          price: "IDR 700.000",
          buyer: "Muhamad Wendy"
        },
        {
          no: 5,
          date: "20-12-2021",
          category: "ADVERTISING & MARKETING",
          statistic:
            "In 2021, global digital video advertising spending was projected to amount to 91.5 billion U.S. dollars, roughly 20 percent ...",
          price: "IDR 700.000",
          buyer: "Muhamad Wendy"
        },
        {
          no: 6,
          date: "20-12-2021",
          category: "ADVERTISING & MARKETING",
          statistic:
            "In 2021, global digital video advertising spending was projected to amount to 91.5 billion U.S. dollars, roughly 20 percent ...",
          price: "IDR 700.000",
          buyer: "Muhamad Wendy"
        }
      ],
      activePage: 4
    };
  }
};
</script>

<style lang="scss">
.transaction-tables {
  .table-wrapper {
    overflow: auto;
  }

  .va-table {
    width: 100%;
  }
}
</style>
